
import { defineComponent, reactive } from "vue";
import Header from "@/components/parts/Header.vue";
import BottomNavigation from "@/components/parts/BottomNavigation.vue";
import store from "@/store";
import DialogBase from "@/components/parts/DialogBase.vue";
import { useRouter } from "vue-router";
import { withdrawal } from "@/components/firebaseOperations";
import { openModal, closeModal } from "@/components/modalFunc";
import constants from "@/components/constants";
import { signOut } from "firebase/auth";

export default defineComponent({
  components: {
    Header,
    BottomNavigation,
    DialogBase,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      deleteModal: false,
      settings: ["ログアウト", "アカウント削除"],
    });

    /// storeに入っているログインユーザデータを空にする
    const deleteStore = () => {
      store.commit("setMyUser", {});
      store.commit("setThanks", {});
    };

    /// ログアウトか退会を行った場合に実行
    /// @param {string} type - "withdrawal" or "logout" (退会かログアウトか)
    const goOutside = async (type: string) => {
      store.dispatch("stopNotificationSubscribe");
      if (type === "withdrawal") {
        await withdrawal();
      }

      deleteStore();
      await signOut(store.state.auth);
      router.push({
        path: "/",
      });
    };

    return {
      constants,
      state,
      openModal,
      closeModal,
      goOutside,
    };
  },
});
